import "tailwindcss/dist/base.css";
import "styles/globalStyles.css";
import React from "react";
import { Switch, Route, BrowserRouter } from "react-router-dom";
import { css } from "styled-components/macro"; //eslint-disable-line

import Hero from "components/hero/_BackgroundAsImage";
import APP from "components/features/_TwoColSingleFeatureWithStats2";
import Identification from "components/features/_Identification";
import Database from "components/features/_Database";
import Team from "components/cta/_Team";
import Footer from "components/footers/_MiniCenteredFooter";

export default function App() {
  return (
    <BrowserRouter>
      <Route path="/">
        <div id="intro">
          <Hero />
        </div>
        <div id="app">
          <APP />
        </div>
        <div>
          <Identification />
        </div>
        <div id="database">
          <Database />
        </div>
        <div id="contact">
          <Team />
        </div>

        <Footer />
      </Route>
    </BrowserRouter>
  );
}
