import React, { useMemo, useState, useCallback, useEffect } from "react";
import { FiExternalLink } from "react-icons/fi";
import { VscLoading } from "react-icons/vsc";
import tw from "twin.macro";
import styled from "styled-components";

import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { ReactComponent as CheckboxIcon } from "images/checkbox-circle.svg";
import { FiUploadCloud } from "react-icons/fi";
import { FaRegHandPointDown, FaExclamationCircle } from "react-icons/fa";
import Dropzone from "components/helper/Dropzone.js";

const PlanFeatures = styled.div`
  ${tw`flex flex-row text-secondary-500 font-semibold pb-3 mt-4 sm:mt-0 items-center justify-center sm:justify-start`}
  .icon {
    ${tw`w-5 h-5 flex-shrink-0`}
  }
  .text {
    ${tw`tracking-wide ml-2`}
  }
`;

const Container = tw.div`flex w-full h-24 sm:h-40 overflow-hidden`;
const ResetButton = tw(PrimaryButtonBase)`bg-gray-300`;

const defaultResult = [
  { class: "H-004", probability: 0.6588 },
  { class: "tmp-001", probability: 0.2614 },
  { class: "H-005", probability: 0.0256 },
];

const species = [
  {
    id: "H-038",
    sciName: "Beilschmiedia erythrophloia Hayata",
    commonName: "瓊楠",
    link: "https://woodsearch.tfri.gov.tw/wood.php?c=2MrFn5donA%3D%3D",
  },
  {
    id: "H-004",
    sciName: "Cinnamomum camphora (L.) J. Persl",
    commonName: "樟樹",
    link: "https://woodsearch.tfri.gov.tw/wood.php?c=2MrFn5hkmg%3D%3D",
  },
  {
    id: "H-005",
    sciName: "Cinnamomum kanehirae Hayata",
    commonName: "牛樟",
    link: "https://woodsearch.tfri.gov.tw/wood.php?c=2MrFn5hkmw%3D%3D",
  },
  {
    id: "tmp-001",
    sciName: "Cinnamomum micranthum (Hayata) Hayata",
    commonName: "冇樟",
    link: "https://woodsearch.tfri.gov.tw/wood.php?c=2MrFn5hkmw%3D%3D",
  },
  {
    id: "H-006",
    sciName: "Cinnamomum subavenium Miq.",
    commonName: "香桂",
    link: "https://woodsearch.tfri.gov.tw/wood.php?c=2MrFn5hknA%3D%3D",
  },
  {
    id: "H-007",
    sciName:
      "Machilus japonica Siebold ＆ Zucc. Var. kusanoi (Hayata) J. C. Liao",
    commonName: "大葉楠",
    link: "https://woodsearch.tfri.gov.tw/wood.php?c=2MrFn5pjlA%3D%3D",
  },
  {
    id: "H-008",
    sciName: "Machilus thunbergii Siebold ＆ Zucc.",
    commonName: "紅楠",
    link: "https://woodsearch.tfri.gov.tw/wood.php?c=2MrFn5pjlQ%3D%3D",
  },
  {
    id: "H-009",
    sciName: "Machilus zuihoensis Hayata",
    commonName: "香楠",
    link: "https://woodsearch.tfri.gov.tw/wood.php?c=2MrFn5pjlg%3D%3D",
  },
];

const Result = () => {
  const [image, setImage] = useState(null);
  const [result, setResult] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (image) {
      onPredictHandler();
    }
  }, [image]);

  const onResetHandler = () => {
    setImage(null);
    setResult(null);
  };

  const onPredictHandler = () => {
    const url = "https://6220800898ab.ngrok.io/predict";
    setLoading(true);
    fetch(url, {
      method: "POST",
      body: JSON.stringify(image.split(",")[1]),
      headers: new Headers({
        "Content-Type": "application/json",
      }),
    })
      .then((res) => res.json())
      .catch((error) => {
        console.error("Error:", error);
        setError(true);
        setImage("https://i.imgur.com/6yuTNEx.png");
      })
      .then((response) => {
        console.log("Success:", response);
        !error && setResult(response);
        error && setResult(defaultResult);
        setLoading(false);
      });
  };

  return (
    <>
      {!image && !loading && (
        <PlanFeatures>
          <FaRegHandPointDown className="icon" />
          <span className="text">上傳照片，體驗辨識系統</span>
        </PlanFeatures>
      )}

      {loading && (
        <PlanFeatures>
          <FiUploadCloud className="icon" />
          <span className="text">鑑定中...</span>
        </PlanFeatures>
      )}

      {!loading && result && !error && (
        <PlanFeatures>
          <CheckboxIcon className="icon" />
          <span className="text">以下是您的鑑定結果</span>
        </PlanFeatures>
      )}

      {!loading && error && (
        <PlanFeatures>
          <FaExclamationCircle className="icon" css={tw`text-danger-500`} />
          <span className="text" css={tw`text-danger-500`}>
            很抱歉，伺服器正在維護或忙碌中，請稍後再試。 以下是辨識範例：
          </span>
        </PlanFeatures>
      )}

      <Container>
        {!image && (
          <div css={tw`w-full`}>
            <Dropzone css={tw`flex-1`} setImage={setImage} />
          </div>
        )}

        {loading && (
          <VscLoading
            css={tw`my-auto mx-auto text-primary-500 text-3xl animate-spin`}
          />
        )}
        {result && !loading && (
          <>
            <div>
              <img css={tw`rounded-md h-24 sm:h-40 select-none`} src={image} />
            </div>
            <div css={tw`flex flex-col flex-1 justify-between`}>
              {result.map((result, index) => (
                <div css={tw`flex flex-1 items-center justify-between`}>
                  <div css={tw`flex items-center`}>
                    <div
                      css={tw`pl-4 sm:pl-8 text-xl sm:text-4xl font-black text-primary-300`}
                    >
                      {index + 1}
                    </div>
                    <div css={tw`pl-4 flex flex-col items-start`}>
                      <div css={tw`text-xl font-black text-primary-900`}>
                        {species.find((s) => s.id == result.class).commonName}
                        <span
                          css={tw`pl-2 text-sm font-normal text-primary-900`}
                        >
                          ({parseInt(result.probability * 100)}%)
                        </span>
                      </div>
                      <div
                        css={tw`hidden sm:flex text-sm font-thin text-primary-500`}
                      >
                        {species.find((s) => s.id == result.class).sciName}
                      </div>
                    </div>
                  </div>
                  <a
                    css={tw`pl-8 text-primary-900 my-auto text-2xl`}
                    target="_new"
                    href={species.find((s) => s.id == result.class).link}
                  >
                    <FiExternalLink />
                  </a>
                </div>
              ))}
            </div>
          </>
        )}

        {/* <div className="flex ml-auto mt-4 gap-2">
          {result && !loading && (
            <ResetButton onClick={() => onResetHandler()}>Reset</ResetButton>
          )}
        </div> */}
      </Container>
    </>
  );
};

export default Result;
