import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { NavHashLink } from "react-router-hash-link";

import { Container as ContainerBase } from "components/misc/Layouts.js";
import logo from "../../images/logo-white.svg";

import { RiMapPinFill } from "react-icons/ri";
import { MdEmail } from "react-icons/md";
import { AiFillPhone } from "react-icons/ai";

const Container = tw(ContainerBase)`bg-primary-900 text-primary-100`;
const Content = tw.div`max-w-screen-xl mx-auto py-12 lg:py-12`;

const Row = tw.div`flex items-center justify-center flex-col px-8`;

const LogoContainer = tw.div`flex items-center justify-center md:justify-start`;
const LogoImg = tw.img`w-8`;
const LogoText = tw.h5`ml-2 text-2xl font-black tracking-wider`;

const LinksContainer = tw.div`mt-8 hidden sm:flex flex-wrap justify-center items-center flex-col sm:flex-row`;
const Link = tw(
  NavHashLink
)`font-bold border-b-2 border-transparent hocus:text-gray-300 hocus:border-gray-300 pb-1 transition duration-300 mt-2 mx-4`;

const SocialLinksContainer = tw.div`mt-6 sm:mt-10`;
const SocialLink = styled.a`
  ${tw`cursor-pointer inline-block text-gray-100 hover:text-gray-500 transition duration-300 mx-4`}
  svg {
    ${tw`w-5 h-5`}
  }
`;

const CopyrightText = tw.p`text-center mt-6 sm:mt-10 font-medium tracking-wide text-sm text-primary-500`;
export default () => {
  return (
    <Container>
      <Content>
        <Row>
          <LogoContainer>
            <LogoImg src={logo} />
            <LogoText>木材辨識</LogoText>
          </LogoContainer>
          <LinksContainer>
            <Link smooth to="/#intro">
              介紹
            </Link>
            <Link smooth to="/#app">
              APP下載
            </Link>
            <Link smooth to="/#identification">
              辨識系統體驗
            </Link>
            <Link smooth to="/#database">
              影像資料庫
            </Link>
            {/* <Link href="#">聯絡我們</Link> */}
          </LinksContainer>
          <SocialLinksContainer>
            <SocialLink href="mailto:b05611002@g.ntu.edu.tw">
              <MdEmail />
            </SocialLink>
            <SocialLink href="tel:0233665365">
              <AiFillPhone />
            </SocialLink>
            <SocialLink
              target="_blank"
              href="https://www.google.com.tw/maps/place/%E5%9C%8B%E7%AB%8B%E8%87%BA%E7%81%A3%E5%A4%A7%E5%AD%B8%E7%94%9F%E7%89%A9%E7%94%A2%E6%A5%AD%E6%A9%9F%E9%9B%BB%E5%B7%A5%E7%A8%8B%E7%B3%BB/@25.0177019,121.5424841,18.82z/data=!4m5!3m4!1s0x3442aa2429043393:0xcfa54254447302e2!8m2!3d25.0179359!4d121.5429593?hl=zh-TW"
            >
              <RiMapPinFill />
            </SocialLink>
          </SocialLinksContainer>
          <CopyrightText>
            &copy; Copyright 2021, Kuan-Ting Yeh. <br css={tw`sm:hidden`} /> All
            Rights Reserved.
          </CopyrightText>
        </Row>
      </Content>
    </Container>
  );
};
