import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import Static from "components/helper/Static.js";
import Result from "components/helper/Result.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import StatsIllustrationSrc from "images/system-pic.svg";
import { ReactComponent as SvgDotPattern } from "images/dot-pattern.svg";


const Background = tw.div`w-full bg-primary-100`;
const Container = tw.div`relative relative px-4 sm:px-8 max-w-screen-xl mx-auto pt-20 pb-4 md:py-32`;
const TwoColumn = tw.div`flex flex-col px-4 md:flex-row justify-between `;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(
  Column
)`md:w-5/12 flex-shrink-0 h-80 py-4 sm:py-8 md:h-auto relative`;
const TextColumn = styled(Column)((props) => [
  tw`md:w-7/12 sm:mt-16 md:mt-0 pb-8`,
  props.textOnLeft
    ? tw`md:mr-12 lg:mr-16 md:order-first`
    : tw`md:ml-12 lg:ml-16 md:order-last`,
]);

const Image = styled.div((props) => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-contain bg-no-repeat bg-center h-full`,
]);
const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Heading = tw(SubheadingBase)`
text-primary-900 font-black sm:pb-4 text-3xl sm:text-5xl lg:text-6xl text-center sm:text-left leading-tight`;
const Description = tw.p`mt-4 md:mt-2 text-justify md:text-left text-base md:text-base lg:text-lg font-medium leading-relaxed text-primary-900`;

const PrimaryButton = tw(
  PrimaryButtonBase
)`mt-8 md:mt-10 text-sm inline-block mx-auto md:mx-0`;


export default ({
  subheading = "Our Track Record",
  heading = <>深度學習辨識系統</>,
  description = "我們採用近年流行的深度學習演算法作為木材影像的分類器，透過給予大量的木材影像作為訓練資料，分類器可以自動學習橫切面影像上的導管、管胞的大小與排列方式，進而了解不同物種獨一無二的特徵。尤其透過模型的輕量化，每張影像都能在1秒內得出辨識結果，大大提高工作效率。",
  primaryButtonText = "Learn More",
  primaryButtonUrl = "https://timerse.com",
  imageSrc = StatsIllustrationSrc,
  imageCss = null,
  imageContainerCss = null,
  imageInsideDiv = true,
  textOnLeft = false,
}) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.
  //Change the statistics variable as you like, add or delete objects

  return (
    <>
      <Static />
      <div id="identification">
        <Background>
          <Container>
            <TwoColumn css={!imageInsideDiv && tw`md:items-center`}>
              <ImageColumn css={imageContainerCss}>
                {imageInsideDiv ? (
                  <Image loading="eager" imageSrc={imageSrc} css={imageCss} />
                ) : (
                  <img src={imageSrc} css={imageCss} alt="" />
                )}
              </ImageColumn>
              <TextColumn textOnLeft={textOnLeft}>
                <TextContent>
                  <Heading>{heading}</Heading>
                  <Description>{description}</Description>
                </TextContent>

                <Result />
              </TextColumn>
            </TwoColumn>
          </Container>
        </Background>
      </div>
    </>
  );
};
