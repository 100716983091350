import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line

import { HashLink, NavHashLink } from 'react-router-hash-link';

import Header, {
  NavLink,
  NavLinks,
  PrimaryLink,
  LogoLink,
  NavToggle,
  DesktopNavLinks,
} from "../headers/light.js";
import ResponsiveVideoEmbed from "../../helpers/ResponsiveVideoEmbed.js";

const StyledHeader = styled(Header)`
  ${tw`pt-8 max-w-none`}
  ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
    ${tw`text-gray-100 hover:border-gray-300 hover:text-gray-300`}
  }
  ${NavToggle}.closed {
    ${tw`text-gray-100 hover:text-primary-500`}
  }
`;
const Container = styled.div`
  ${tw`relative bg-center bg-cover md:min-h-screen`}
  background-image: url("https://i.imgur.com/73wgqtQ.jpg");
`;

const OpacityOverlay = tw.div`z-10 absolute inset-y-0 left-0 w-2/3 bg-gradient-to-r from-black opacity-75`;

const HeroContainer = tw.div`z-20 relative px-4 sm:px-8 max-w-screen-xl mx-auto`;
const TwoColumn = tw.div`pt-8 sm:pb-32 px-4 flex justify-between items-center flex-col lg:flex-row`;
const LeftColumn = tw.div`flex flex-col xl:w-2/3 items-center lg:items-start`;
const RightColumn = tw.div`w-full lg:w-1/2 mt-16 lg:mt-0 lg:pl-8`;

const Heading = styled.h1`
  ${tw`text-4xl text-center md:text-left sm:text-5xl lg:text-6xl xl:text-6xl font-black text-gray-100 leading-none`}
  span {
    ${tw`inline-block md:mt-24`}
  }
`;

const TopStory = styled.span`
  ${tw` text-base md:text-lg text-justify md:text-left font-bold mt-6 sm:mt-12 xl:mt-16 text-gray-100 leading-normal`}
`;

const Story = styled.span`
  ${tw`text-base  md:text-lg text-justify md:text-left mt-4 sm:mt-6 text-gray-100 leading-snug`}
`;

const PrimaryAction = tw.button`px-8 py-3 mt-10 text-sm sm:text-base sm:mt-16 sm:px-8 sm:py-4 bg-gray-100 text-primary-500 font-bold rounded shadow transition duration-300 hocus:bg-primary-500 hocus:text-gray-100 focus:shadow-outline`;

const StyledResponsiveVideoEmbed = styled(ResponsiveVideoEmbed)`
  padding-bottom: 56.25% !important;
  padding-top: 0px !important;
  ${tw`rounded`}
  iframe {
    ${tw`rounded bg-black shadow-xl`}
  }
`;

const StaticContainer = tw.div`relative -mt-28 px-12 mx-auto h-48 lg:h-48 z-20 lg:max-w-screen-xl `;
const Static = tw.div`h-full bg-white shadow-se flex justify-between px-16`;
const StaticBox = styled.div`
  ${tw`flex flex-col items-start text-primary-900 justify-center`}
`;
const StaticInfo = tw.span`text-xl text-primary-900`
const StaticNum = tw.span`text-6xl font-black text-secondary-500`

const VerticalLine = tw.div`w-px bg-primary-100 h-auto my-4`

export default () => {
  const navLinks = [
    <NavLinks key={1}>
      {/* <NavLink smooth to="/#intro">介紹</NavLink> */}
      <NavLink smooth to="/#app">APP下載</NavLink>
      <NavLink smooth to="/#identification">辨識系統體驗</NavLink>
      <NavLink smooth to="/#database">影像資料庫</NavLink>
      <NavLink smooth to="/#contact">聯絡我們</NavLink>
    </NavLinks>,
  ];

  return (
    <>
      <Container>
        <OpacityOverlay />
        <HeroContainer>
          <StyledHeader links={navLinks} />
          <TwoColumn>
            <LeftColumn>
              <Heading>
                <span>木材辨識計畫</span>
              </Heading>
              <TopStory>
                臺灣天然林禁伐30餘年，矯枉過正的環保觀念導致林業長年停滯。近年林務局開始倡議國產材的使用，陸續推動「臺灣林產品生產追溯條碼」、重啟貴重木標售與規劃貴重木出口管制，在以新興科技建立追朔制度之際，利用AI技術自動化辨識木材種類將扮演暢通產銷流程的關鍵角色。
              </TopStory>
              <Story>
                為重啟停滯多年的林業列車，林務局努力從市場角度切入，以「環保綠建材、友善環境生產、減少碳足跡」包裝國產材的整體形象，爭取消費者認同並開拓市場。然而，早已斷裂的國產材供應鏈導致林業專業人員減少，若因現有人力無法負荷林產品制度所增加的驗證與管制工作，導致制度推動不順、業者與消費者失去信心，實屬可惜。
              </Story>
            </LeftColumn>
            <RightColumn></RightColumn>
          </TwoColumn>
        </HeroContainer>
      </Container>
      {/* <StaticContainer>
        <Static>
          <StaticBox>
            <StaticInfo>辨識準確率</StaticInfo>
            <StaticNum>94.5%</StaticNum>
          </StaticBox>
          <VerticalLine />
          <StaticBox>
            <StaticInfo>可辨識物種數</StaticInfo>
            <StaticNum>10</StaticNum>
          </StaticBox>
          <VerticalLine />
          <StaticBox>
            <StaticInfo>木材影像數量</StaticInfo>
            <StaticNum>352</StaticNum>
          </StaticBox>
          <VerticalLine />
          <StaticBox>
            <StaticInfo>資料庫物種數量</StaticInfo>
            <StaticNum>49</StaticNum>
          </StaticBox>
        </Static>
      </StaticContainer> */}
    </>
  );
};
