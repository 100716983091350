import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import StatsIllustrationSrc from "images/system-pic.svg";
import { ReactComponent as SvgDotPattern } from "images/dot-pattern.svg";

const StaticContainer = tw.div`relative -mb-16 sm:-mb-12 px-8 sm:px-12 mx-auto h-48 lg:h-48 z-20 lg:max-w-screen-xl `;
const Static = tw.div`h-full bg-white shadow-se grid grid-cols-2 md:flex justify-between px-2 py-2 sm:px-16`;
const StaticBox = styled.div`
  ${tw`flex flex-col items-center sm:items-start text-primary-900 justify-center`}
`;
const StaticInfo = tw.span`md:text-xl text-primary-900`;
const StaticNum = tw.span`text-3xl md:text-6xl font-black text-secondary-500`;

const VerticalLine = tw.div`w-px bg-primary-100 h-auto my-4 hidden md:flex`;

export default () => {
  return (
    <StaticContainer>
      <Static>
        <StaticBox>
          <StaticInfo>辨識準確率</StaticInfo>
          <StaticNum>94.5%</StaticNum>
        </StaticBox>
        <VerticalLine />
        <StaticBox>
          <StaticInfo>可辨識物種數</StaticInfo>
          <StaticNum>10</StaticNum>
        </StaticBox>
        <VerticalLine />
        <StaticBox>
          <StaticInfo>木材影像數量</StaticInfo>
          <StaticNum>352</StaticNum>
        </StaticBox>
        <VerticalLine />
        <StaticBox>
          <StaticInfo>資料庫物種數量</StaticInfo>
          <StaticNum>49</StaticNum>
        </StaticBox>
      </Static>
    </StaticContainer>
  );
};
