import React, { useMemo, useCallback } from "react";
import tw from "twin.macro";
import { useDropzone } from "react-dropzone";

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "50px",
  borderWidth: 2,
  borderRadius: 5,
  borderColor: "#909090",
  borderStyle: "dashed",
  backgroundColor: "rgba(194,207,207,0.0)",
  color: "#909090",
  outline: "none",
  transition: "color border .24s ease-in-out",
  cursor: "pointer"
};

const activeStyle = {
  borderColor: "#F7BA34",
};

const acceptStyle = {
  borderColor: "#477B72",
  color: "#477B72"
};

const rejectStyle = {
  borderColor: "#E54513",
  color: "#E54513"
};

export default function Basic(props) {
  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();

      reader.onload = () => {
        // Do whatever you want with the file contents
        const str = reader.result;
        console.log(str);
        props.setImage(str);
      };
      reader.readAsDataURL(file);
    });
  }, []);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({ onDrop, accept: "image/*", maxFiles: 1 });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  return (
    <div>
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        {isDragReject && (<p>
          您所選擇的檔案無法上傳
          <br /> 請重新選擇
        </p>)}
        {!isDragReject && (<p >
          點擊選擇影像
          <br /> 或將檔案拖曳至這裡
        </p>)}
        
      </div>
    </div>
  );
}
