import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-9.svg";
import { ContentWithPaddingXl, Container } from "components/misc/Layouts";

const PrimaryBackgroundContainer = styled.div`
  ${tw`py-8 sm:py-12 lg:py-16 shadow-xl rounded-md sm:rounded-xl relative bg-center bg-cover`}
  background-image: url("https://i.imgur.com/PgJBW0L.jpg");
`;
const OpacityOverlay = tw.div`z-10 absolute inset-0 bg-primary-900 opacity-25`;

const Row = tw.div`px-4 sm:px-16 mx-auto flex justify-center items-center relative z-10 flex-col lg:px-24 lg:flex-row text-center lg:text-left`;

const TextContainer = tw.div`lg:w-3/4  text-xl sm:text-3xl font-semibold`;
const Subheading = tw.h6`text-white`;
const Heading = tw.h5`text-white`;

const LinksContainer = tw.div`lg:w-1/4 flex justify-center lg:justify-end mt-6 lg:mt-0 flex-col sm:flex-row`;

const Link = tw.a`w-full sm:w-auto text-sm sm:text-lg px-6 py-3 sm:px-8 sm:py-4 lg:px-10 lg:py-4 mt-4 first:mt-0 sm:mt-0 sm:mr-8 sm:last:mr-0 font-bold border border-transparent tracking-widest transition duration-300 focus:outline-none focus:shadow-outline`;
const PrimaryLink = tw(
  Link
)`shadow text-gray-100 hocus:text-gray-300 bg-primary-500 hocus:bg-primary-700`;

const SecondaryLink = tw(
  Link
)`sm:border-4 border-white text-primary-900 sm:text-white hover:text-primary-900 bg-white sm:bg-transparent hover:bg-white`;

const DecoratorBlobContainer = tw.div`absolute inset-0 overflow-hidden rounded-lg`;
const DecoratorBlob1 = tw(
  SvgDecoratorBlob1
)`absolute bottom-0 left-0 w-80 h-80 transform -translate-x-20 translate-y-32 text-secondary-100 opacity-50`;
const DecoratorBlob2 = tw(
  SvgDecoratorBlob1
)`absolute top-0 right-0 w-80 h-80 transform  translate-x-20 -translate-y-64 text-secondary-100 opacity-50`;
export default ({
  subheading = "本團隊為國立台灣大學生物機電工程學系",
  heading = "機器學習與機器視覺實驗室",
  primaryLinkText = "Get Started",
  primaryLinkUrl = "http://timerse.com",
  secondaryLinkText = "了解我們",
  secondaryLinkUrl = "https://sites.google.com/view/mlmv/",
  pushDownFooter = false,
}) => {
  return (
    <Container css={pushDownFooter && tw`mb-8 lg:mb-16`}>
      <ContentWithPaddingXl css={tw`pt-2 sm:pt-8 pb-8 sm:pb-16 px-8 sm:px-12`}>
        <PrimaryBackgroundContainer>
          <Row>
            <TextContainer>
              <Subheading>
                本團隊為國立台灣大學
                <br css={tw`sm:hidden`} />
                生物機電工程學系
              </Subheading>
              <Heading>{heading}</Heading>
            </TextContainer>
            <LinksContainer>
              {/* <PrimaryLink href={primaryLinkUrl}>{primaryLinkText}</PrimaryLink> */}
              <SecondaryLink href={secondaryLinkUrl} target="_blank">
                {secondaryLinkText}
              </SecondaryLink>
            </LinksContainer>
          </Row>
          {/* <DecoratorBlobContainer>
          <DecoratorBlob1/>
          <DecoratorBlob2/>
        </DecoratorBlobContainer> */}
        </PrimaryBackgroundContainer>
      </ContentWithPaddingXl>
    </Container>
  );
};
