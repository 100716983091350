import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import StatsIllustrationSrc from "images/mobile-pic.png";
import QrcodeIOSSrc from "images/qrcode-ios.svg";
import QrcodeAndroidSrc from "images/qrcode-android.svg";
import LogoSrc from "images/logo-default.svg";

const Container = tw.div`relative relative px-4 sm:px-8 max-w-screen-xl mx-auto pt-4 pb-12 md:py-24`;
const TwoColumn = tw.div`flex flex-col px-4 md:flex-row justify-between `;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-96 md:h-auto relative`;
const TextColumn = styled(Column)((props) => [
  tw`md:w-1/2 mt-4 md:mt-0`,
  props.textOnLeft ? tw` md:order-first` : tw` md:order-last`,
]);

const Image = styled.div((props) => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-contain bg-no-repeat bg-center h-full`,
]);
const TextContent = tw.div`text-center md:text-left`;

const HeadingContainer = tw.div`flex flex-row justify-center items-center gap-1 sm:gap-4`;
const HeadingImage = tw.img`h-12 w-12 sm:h-24 sm:w-24`;

const Heading = tw(SubheadingBase)`
sm:flex-1 text-primary-900 font-black sm:py-8 text-3xl sm:text-5xl lg:text-6xl text-left leading-tight`;
const Description = tw.p`mt-4 md:mt-2 text-justify md:text-left text-base md:text-base lg:text-lg font-medium leading-relaxed text-primary-900`;

const PrimaryButton = tw(
  PrimaryButtonBase
)`mt-8 md:mt-10 text-sm inline-block mx-auto md:mx-0`;

const QRCodeContainer = tw.div`hidden sm:flex flex-row justify-center sm:justify-start mt-4 gap-4 lg:mt-8`;
const QRCode = styled.div`
  ${tw`flex flex-col items-center justify-center`}
  span {
    ${tw`text-center text-sm text-primary-900`}
  }
  img {
    ${tw`h-28 w-28`}
  }
`;

const AppLinkContainer = tw.div`sm:hidden flex justify-center mt-8 gap-4`;
const AppLink = styled.a`
  ${tw`flex-1 rounded-xl`}
  img {
    ${tw``}
  }
`;

export default ({
  heading = <>木材辨識APP</>,
  description = "本團隊和林試所研究員合作，利用AI技術中的深度學習演算法搭配影像處理技術，開發了首款木材物種辨識的手機應用程式(APP)，僅需搭配放大鏡取得木材橫切面照片，就能即時得到辨識結果。不同於以往使用檢索表的辨識方式，對林業現場工作將有相當大的助益。",
  description2 = "原本培育一名專業木材鑑定人員，需要大專林業細學類畢業外加二至五年的工作經驗；如今使用我們的辨識系統，將有機會降低第一線人員所需的專業門檻。林務局於每年五月辦理的漂流木辨識、林產法規、漂流木註記及防範竊取等專業訓練，搭配我們的辨識系統能大幅增加訓練的效率，讓更多人力快速投入現場工作。",
  imageSrc = StatsIllustrationSrc,
  imageCss = null,
  imageContainerCss = null,
  imageInsideDiv = true,
  statistics = null,
  textOnLeft = true,
}) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.
  //Change the statistics variable as you like, add or delete objects

  return (
    <Container>
      <TwoColumn css={!imageInsideDiv && tw`md:items-center`}>
        <ImageColumn css={imageContainerCss}>
          {imageInsideDiv ? (
            <Image imageSrc={imageSrc} css={imageCss} />
          ) : (
            <img src={imageSrc} css={imageCss} alt="" />
          )}
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            <HeadingContainer>
              <HeadingImage src={LogoSrc}></HeadingImage>
              <Heading>{heading}</Heading>
            </HeadingContainer>

            <Description>{description}</Description>
            <Description>{description2}</Description>
            <QRCodeContainer>
              <QRCode>
                <img src={QrcodeIOSSrc}></img> <span>iOS。下載測試</span>
              </QRCode>
              <QRCode>
                <img src={QrcodeAndroidSrc}></img>
                <span>Android。搶先體驗</span>
              </QRCode>
            </QRCodeContainer>
            <AppLinkContainer>
              <AppLink href={"https://apps.apple.com/tw/app/%E6%9C%A8%E6%9D%90%E8%BE%A8%E8%AD%98/id1575710116"} target="_blank">
                <img
                  src={
                    "https://upload.wikimedia.org/wikipedia/commons/thumb/3/3c/Download_on_the_App_Store_Badge.svg/640px-Download_on_the_App_Store_Badge.svg.png"
                  }
                />
              </AppLink>
              <AppLink href={"https://drive.google.com/drive/folders/1qieB4KFTwo9_SoauXQkn8ryF41svaeOf?usp=sharing"} target="_blank">
                <img
                  src={
                    "https://upload.wikimedia.org/wikipedia/commons/thumb/7/78/Google_Play_Store_badge_EN.svg/1200px-Google_Play_Store_badge_EN.svg.png"
                  }
                />
              </AppLink>
            </AppLinkContainer>
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};
