import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { PrimaryLink as PrimaryLinkBase } from "components/misc/Links.js";
import StatsIllustrationSrc from "images/woods.png";
import QrcodeSrc from "images/qrcode.svg";
import LogoSrc from "images/logo-default.svg";
import { ReactComponent as SvgDotPattern1 } from "images/svg-decorator-blob-8.svg";
import { ReactComponent as SvgDotPattern2 } from "images/svg-decorator-blob-5.svg";
import { ReactComponent as ArrowRightIcon } from "images/arrow-right-icon.svg";

const Container = tw.div`relative overflow-hidden`;
const TwoColumn = tw.div`flex flex-col px-8 sm:px-12 mx-auto md:flex-row justify-between max-w-screen-xl py-2 sm:py-20`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-80 md:h-auto relative`;
const TextColumn = styled(Column)((props) => [
  tw`md:w-1/2 md:mt-0`,
  props.textOnLeft ? tw` md:order-first` : tw` md:order-last`,
]);

const Image = styled.div((props) => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-contain bg-no-repeat bg-center h-full`,
]);
const TextContent = tw.div`text-center md:text-left mb-8 mt-4 sm:mt-0`;
const DecoratorBlob1 = styled(SvgDotPattern1)((props) => [
  tw`w-144 h-144 absolute right-0 top-0 transform translate-x-1/2 fill-current text-primary-100 -z-10`,
]);
const DecoratorBlob2 = styled(SvgDotPattern2)((props) => [
  tw`w-64 h-64 absolute left-0 bottom-0 transform -translate-x-1/2 translate-y-1 fill-current text-secondary-300 opacity-50 -z-10`,
]);

const Heading = tw(SubheadingBase)`
text-primary-900 font-black sm:pb-4 text-3xl sm:text-5xl lg:text-6xl text-center sm:text-left leading-tight`;
const Description = tw.p`mt-4 md:mt-2 text-justify md:text-left text-base md:text-base lg:text-lg font-medium leading-relaxed text-primary-900`;

const PrimaryLink = styled(PrimaryLinkBase)`
  ${tw`inline-flex justify-center xl:justify-start items-center mt-4 sm:mt-8 sm:text-lg`}
  svg {
    ${tw`ml-2 w-4 h-4 sm:w-5 sm:h-5`}
  }
`;

export default ({
  heading = <>影像資料庫</>,
  description = "為了訓練辨識分類器，我們從林試所的標本館和林務局的留存樣本蒐集了大量的影像資料。每個木材樣本的影像取得都必須先透過人工修整木材表面，確認樣本特徵清楚顯露後再加以清潔，最後才能使用平板掃描機保存影像。未來我們計畫將資料庫開源，期望能幫助其他生物多樣性與衍生經濟價值的研究。",
  description2 = "透過本應用程式，有效的降低專業人力不足對現今台灣林業發展造成的限制，讓國家所推動的木材相關政策與制度能夠順利上線，達成杜絕走私以及木材販售等指標。也希冀透過AI以及傳統林業的結合，使停滯已久的台灣林業復甦，讓消費者能對標章認證的國產材有足夠的信心，使國產材再次成為市場上的寵兒。",
  imageSrc = StatsIllustrationSrc,
  imageCss = null,
  imageContainerCss = null,
  imageInsideDiv = true,
  statistics = null,
  textOnLeft = true,
}) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.
  //Change the statistics variable as you like, add or delete objects

  return (
    <Container>
      <DecoratorBlob1 />
      <DecoratorBlob2 />
      <TwoColumn css={!imageInsideDiv && tw`md:items-center`}>
        <ImageColumn css={imageContainerCss}>
          {imageInsideDiv ? (
            <Image imageSrc={imageSrc} css={imageCss} />
          ) : (
            <img src={imageSrc} css={imageCss} alt="" />
          )}
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            <Heading>{heading}</Heading>

            <Description>{description}</Description>
            <Description>{description2}</Description>
            <PrimaryLink href='https://drive.google.com/drive/folders/16IIwZi7stICOTbJKp0WChGwZn8VW3KRd?usp=sharing' target='_blank'>
                查看範例影像 <ArrowRightIcon />
              </PrimaryLink>
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};
